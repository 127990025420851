const Signup = () => {
  return (
    <div className="sign-up">
      <div class="center flex">
        <section class="card center">
          <div class="d-logo center flex">
            {/* <img
          class="logo"
          src="https://www.ifatih.com/images/logos/8/4-content-0-logo-1-full-black_3x.png"
          alt="logo"
        /> */}
            <p>FRS AutoSoft Innovations</p>
          </div>
          <div class="title">
            <h4 class="center flex roboto-thin">One account</h4>
            <h4 class="center flex roboto-thin">Many possibilities</h4>
          </div>

          <form action="post">
            <section class="form">
              <input
                class="center flex roboto-thin"
                type="email"
                name="email"
                id="email"
                placeholder="E-mail address or phone number"
              />
              <input
                class="center flex roboto-thin"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
              />
              <div class="space-between">
                <div class="center flex">
                  <input type="checkbox" name="rememberMe" id="rememberMe" />
                  <label class="text-size-10 roboto-thin" for="rememberMe">
                    Remember me
                  </label>
                </div>
                <div class="center flex">
                  <a class="link text-size-10 roboto-thin" href="">
                    <span>Forgot password?</span>
                  </a>
                </div>
              </div>
            </section>

            <section class="buttons">
              <button class="center flex button button-primary" type="submit">
                Sign in
              </button>
              <button class="center flex button button-secondary" type="submit">
                <svg
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M30.0014 16.3109C30.0014 15.1598 29.9061 14.3198 29.6998 13.4487H16.2871V18.6442H24.1601C24.0014 19.9354 23.1442 21.8798 21.2394 23.1864L21.2127 23.3604L25.4536 26.58L25.7474 26.6087C28.4458 24.1665 30.0014 20.5731 30.0014 16.3109Z"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M16.2863 29.9998C20.1434 29.9998 23.3814 28.7553 25.7466 26.6086L21.2386 23.1863C20.0323 24.0108 18.4132 24.5863 16.2863 24.5863C12.5086 24.5863 9.30225 22.1441 8.15929 18.7686L7.99176 18.7825L3.58208 22.127L3.52441 22.2841C5.87359 26.8574 10.699 29.9998 16.2863 29.9998Z"
                      fill="#34A853"
                    ></path>
                    <path
                      d="M8.15964 18.769C7.85806 17.8979 7.68352 16.9645 7.68352 16.0001C7.68352 15.0356 7.85806 14.1023 8.14377 13.2312L8.13578 13.0456L3.67083 9.64746L3.52475 9.71556C2.55654 11.6134 2.00098 13.7445 2.00098 16.0001C2.00098 18.2556 2.55654 20.3867 3.52475 22.2845L8.15964 18.769Z"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M16.2864 7.4133C18.9689 7.4133 20.7784 8.54885 21.8102 9.4978L25.8419 5.64C23.3658 3.38445 20.1435 2 16.2864 2C10.699 2 5.8736 5.1422 3.52441 9.71549L8.14345 13.2311C9.30229 9.85555 12.5086 7.4133 16.2864 7.4133Z"
                      fill="#EB4335"
                    ></path>
                  </g>
                </svg>
                Sign in with Google
              </button>
            </section>

            <section class="not-member center flex">
              <span class="text-size-10 roboto-thin">
                {" "}
                Not a member yet?{" "}
                <a class="link" href="">
                  Sign up
                </a>
              </span>
            </section>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Signup;
