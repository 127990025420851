import { connect } from "react-redux";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const Home = ({ sidebarIsOpen }) => {
  return (
    <div className="Home">
      <Header />
      <Sidebar />
      <div style={{ marginLeft: "78px" }}>Home</div>
    </div>
  );
};

export default connect(
  ({ sidebarReducer }) => ({
    sidebarIsOpen: sidebarReducer.isOpen,
  }),
  null
)(Home);
