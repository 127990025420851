import React from "react";
import { connect, useDispatch } from "react-redux";

const Sidebar = ({ sidebarIsOpen }) => {
  const dispatchAction = useDispatch();

  return (
    <div className={sidebarIsOpen ? "sidebar open" : "sidebar"}>
      <div className="logo-details">
        {sidebarIsOpen && (
          <>
            <i className="bx bxl-codepen icon"></i>
            <div className="logo_name">SideMenu</div>
          </>
        )}
        <i
          className={sidebarIsOpen ? "bx bx-menu-alt-right" : "bx bx-menu"}
          onClick={() => {
            console.log("here");
            dispatchAction({
              type: "MANUAL_TOGGLE_SIDEBAR",
              payload: !sidebarIsOpen,
            });
          }}
        ></i>
      </div>
      <ul className="nav-list">
        <li>
          <i className="bx bx-search"></i>
          <input type="text" placeholder="Search..." />
          <span className="tooltip">Search</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-grid-alt"></i>
            <span className="links_name">Dashboard</span>
          </a>
          <span className="tooltip">Dashboard</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-user"></i>
            <span className="links_name">User</span>
          </a>
          <span className="tooltip">User</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-chat"></i>
            <span className="links_name">Messages</span>
          </a>
          <span className="tooltip">Messages</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-pie-chart-alt-2"></i>
            <span className="links_name">Analytics</span>
          </a>
          <span className="tooltip">Analytics</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-folder"></i>
            <span className="links_name">File Manager</span>
          </a>
          <span className="tooltip">Files</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-cart-alt"></i>
            <span className="links_name">Order</span>
          </a>
          <span className="tooltip">Order</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-heart"></i>
            <span className="links_name">Saved</span>
          </a>
          <span className="tooltip">Saved</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-cog"></i>
            <span className="links_name">Setting</span>
          </a>
          <span className="tooltip">Setting</span>
        </li>
        <li className="profile">
          <div className="profile-details">
            <i className="bx bx-export"></i>
            <div className="name_job">
              <div className="name">Logout</div>
            </div>
          </div>
          <i className="bx bx-log-out" id="log_out"></i>
        </li>
      </ul>
    </div>
  );
};

export default connect(
  ({ sidebarReducer }) => ({
    sidebarIsOpen: sidebarReducer.isOpen,
  }),
  null
)(Sidebar);

/**
 * Template Source
 * https://codepen.io/neeraj_1/pen/mdgXdxb?editors=1010
 */
