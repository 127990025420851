import axios from "axios";
import { isJwtExpired } from "jwt-check-expiration";
import tokenDecoder from "jwt-decode";
import { toast } from "react-toastify";
import requestAPI from "../lib/requestAPI";
const baseURL = process.env.REACT_APP_BASE_URL;

export const userLogin = (credentials, navigate) => async (dispatch) => {
  const T_ID = toast.loading("Loging In...");

  try {
    const { data } = await axios.post(
      `${baseURL}/api/auth/login/`,
      credentials
    );
    const decodedToken = tokenDecoder(data.refresh);

    // FETCH USER PROFILE DATA
    // CURRENTLY NO USER PROFILE API
    // SET THAT USER PROFILE DATA TO REDUX AUTH REDUCER
    // USE THE CONFIG WHEN FETCHING USER PROFILE DATA
    // const headerConfig = {
    //   headers: {
    //     Authorization: "Bearer " + data.token,
    //   },
    // };

    localStorage.setItem(
      "authorization",
      JSON.stringify({
        access: data.access,
        refresh: data.refresh,
        tokenExp: decodedToken.exp,
        tokenIdentifier: decodedToken.jti,
        usedTokenType: decodedToken.token_type,
        userType: decodedToken.user_type,
      })
    );

    dispatch({
      type: "SET_USER_BASIC_INFORMATION",
      payload: decodedToken,
    });

    //  In the future it will redirect to home page
    navigate("/projects", {
      replace: true,
    });

    toast.update(T_ID, {
      render: "Login In Sucess",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      toast.update(T_ID, {
        render: "Network Error: " + error.name,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      if (error.response.status == 401) {
        toast.update(T_ID, {
          render: "No Active Account With This Email",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(T_ID, {
          render: "Login In Failed. Server Down.",
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  }
};

export const registerNewAccount = (credentials) => async (dispatch) => {
  const T_ID = toast.loading("Registering New Account...");

  try {
    await axios.post(`${baseURL}/api/auth/signup/`, credentials);

    toast.update(T_ID, {
      render: "Account Successfully Created. Please Verify Your Email.",
      type: "success",
      isLoading: false,
      autoClose: 6000,
    });
  } catch (error) {
    console.log(error.response);

    if (error.response.status == 400 && error.response.data.email) {
      toast.update(T_ID, {
        render: "An Account Already Associated With This Email Address.",
        type: "warning",
        isLoading: false,
        autoClose: 3000,
      });
    } else if (error.response.status == 400 && error.response.data.password) {
      toast.update(T_ID, {
        render: "Password Must Contain 6 Characters",
        type: "warning",
        isLoading: false,
        autoClose: 3000,
      });
    } else if (error.response.status == 500) {
      toast.update(T_ID, {
        render: "Registration Failed. Try Again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(T_ID, {
        render: "Registration Failed. Try Again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }
};

export const fetchUserProfile = () => async (dispatch) => {
  const { data } = await requestAPI().get(`/api/auth/user/`);

  dispatch({
    type: "SET_USER_PROFILE_INFORMATION",
    payload: data
  });
};

export const shareAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  toast.success("Authenticating Unity User...");

  if (authorization && isJwtExpired(authorization.access)) {
    // IMPLEMENT REFRESH TOKEN HERE

    window.location.replace("/");
  } else if (authorization && !isJwtExpired(authorization.access))
    return authorization.access;
  else window.location.replace("/");
};

window.getAccessToken = shareAccessToken;
