import tokenDecoder from "jwt-decode";

const initialValue = {
  user_id: null,
  first_name: null,
  last_name: null,
  email: null,
  is_premium_user: false,
  is_verified: false,
  organization_name: null,
  is_logged_in: false,
  user_profile_photo: null,
  user_profile_guid: null,
  user_webgl_avatar: null,
};

const authorizedUser =
  localStorage.getItem("authorization") &&
  JSON.parse(localStorage.getItem("authorization")).refresh
    ? {
        ...tokenDecoder(
          JSON.parse(localStorage.getItem("authorization")).refresh
        ),
        is_logged_in: true,
      }
    : { ...initialValue };

// WILL WORK ON TOKEN EXPIRATION LATER
// const authorizedUser = {
//   ...exisitingUser,
//   is_logged_in: !exisitingUser.exp * 1000 < Date.now(),
// };

const authReducer = (state = authorizedUser, action) => {
  switch (action.type) {
    case "SET_USER_BASIC_INFORMATION":
      return {
        user_id: action.payload.user_id,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        email: action.payload.email,
        is_premium_user: action.payload.is_premium_user,
        is_verified: action.payload.is_verified,
        organization_name: action.payload.organization_name,
        is_logged_in: true,
        user_profile_photo: action.payload.profile_img_link,
        user_profile_guid: action.payload.guid,
        user_webgl_avatar: action.payload.avatar,
      };
    case "SET_USER_PROFILE_INFORMATION":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
