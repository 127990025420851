import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { fetchUserProfile } from "../actions/authActions";

const ProtectedRoutes = ({ is_logged_in, fetchUserProfileAction }) => {
  const callingFromUseEffct = async () => {
    if (is_logged_in) {
      await fetchUserProfileAction();
    }
  };

  useEffect(() => {
    callingFromUseEffct();
  });

  return is_logged_in ? <Outlet /> : <Navigate to="/" />;
};

export default connect(
  ({ authentication }) => ({
    is_logged_in: authentication.is_logged_in,
  }),
  { fetchUserProfileAction: fetchUserProfile }
)(ProtectedRoutes);
