// import CssBaseline from "@mui/material/CssBaseline";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { Route, Routes } from "react-router-dom";
// All Pages
import Home from "./Home";
import Signin from "./Signin";
import Signup from "./Signup";
import Dashboard from "./Dashboard";
import NotFound from "./NotFound";
import PrivacyPolicy from "./PrivacyPolicy";
import ProtectedRoutes from "./ProtectedRoutes";
import TermsAndConditions from "./TermsAndConditions";

// const darkTheme = createTheme({
//   typography: {
//     fontFamily: "Rajdhani",
//   },
//   palette: {
//     mode: "dark",
//     primary: {
//       main: "#1b9fb5",
//       contrastText: "#fff",
//     },
//     secondary: {
//       main: "#f60808",
//     },
//     background: {
//       default: "#0b1423",
//       paper: "#0d252f",
//     },
//   },
// });

const Pages = () => {
  return (
    // <ThemeProvider theme={darkTheme}>
    // <ThemeProvider>
    //   <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/secret-register" element={<Signup />} />
        <Route path="/*" element={<NotFound />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
        </Route>
      </Routes>
    // </ThemeProvider>
  );
};

export default Pages;
