import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

const Header = ({ sidebarIsOpen }) => {
  const menubtnRef = useRef(null);
  const closebtnRef = useRef(null);

  useEffect(() => {
    const menubtn = menubtnRef.current;
    const closebtn = closebtnRef.current;

    if (menubtn && closebtn) {
      menubtn.onclick = () => {
        menubtn.style.display = "none";
        closebtn.style.display = "block";
      };

      closebtn.onclick = () => {
        closebtn.style.display = "none";
        menubtn.style.display = "block";
      };
    }

    // Cleanup function to remove event listeners
    return () => {
      if (menubtn) {
        menubtn.onclick = null;
      }
      if (closebtn) {
        closebtn.onclick = null;
      }
    };
  }, []);

  return (
    <>
      <div
        className="__header__"
        style={sidebarIsOpen ? { marginLeft: "250px" } : { marginLeft: "50px" }}
      >
        <div className="container">
          <div className="container__logo">Logo{sidebarIsOpen ? 1 : 0}</div>
          <div className="container__menu">
            <a href="#">Home</a>
            <a href="#">About</a>
            <a href="#">Blog</a>
            <a href="#">Support</a>
            <a href="#">Register</a>
          </div>
          <span className="menubtn" ref={menubtnRef}>
            <i className="fa-solid fa-bars"></i>
          </span>
          <span
            className="closebtn"
            ref={closebtnRef}
            style={{ display: "none" }}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default connect(
  ({ sidebarReducer }) => ({
    sidebarIsOpen: sidebarReducer.isOpen,
  }),
  null
)(Header);

/**
 * Template Source
 * https://codepen.io/behan05/pen/bGJgXrB
 */
