import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from "react-dom";
import './index.css';
import './scss/style.scss';
// import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import store from "./store";
import PageRoutes from "./pages";

import "react-toastify/dist/ReactToastify.css";
import "react-toastify/scss/main.scss";
import "./assets/css/bootstrap.min.css";
import 'boxicons/css/boxicons.min.css';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Home />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

ReactDOM.render(
  <BrowserRouter>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick={false}
      draggable={false}
      rtl={false}
      theme="dark"
    />
    <Provider store={store}>
      <PageRoutes />
      <Toaster position="bottom-left" reverseOrder={false} />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
