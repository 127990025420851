const initialState = {
  isOpen: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INITIAL_STATE":
      return initialState;
    case "AUTO_TOGGLE_SIDEBAR":
      return {
        ...state,
        isOpen: !initialState.isOpen,
      };
    case "MANUAL_TOGGLE_SIDEBAR":
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

export default sidebarReducer;
