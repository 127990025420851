import axios from "axios";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  async (request) => {
    const authorizedToken =
      localStorage.getItem("authorization") &&
      JSON.parse(localStorage.getItem("authorization")).access
        ? JSON.parse(localStorage.getItem("authorization")).access
        : null;

    if (localStorage.getItem("authorization") && authorizedToken)
      request.headers["Authorization"] = "Bearer " + authorizedToken;

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    toast.dismiss();
    return response;
  },
  async (error) => {
    console.log("ERROR", error);
    if (error) {
      if (error.response) {
        if (error.response.status === 500) {
          toast(error.response.statusText);
        } else if (error.response.status === 401) {
          localStorage.removeItem("authorization");
          window.location.replace("/");
        } else if (error.response.status === 403) {
          localStorage.removeItem("authorization");
          window.location.replace("/");
        }
      }
    }

    return Promise.reject(error);
  }
);

const requestAPI = () => {
  return {
    get: (url) => {
      return axiosInstance.get(`${baseURL + url}`);
    },
    post: (url, payload) => {
      return axiosInstance.post(`${baseURL + url}`, payload);
    },

    put: (url, payload) => {
      return axiosInstance.put(`${baseURL + url}`, payload);
    },
    patch: (url, payload) => {
      return axiosInstance.patch(`${baseURL + url}`, payload);
    },
    delete: (url) => {
      return axiosInstance.delete(`${baseURL + url}`);
    },
  };
};

export default requestAPI;
