import thunk from "redux-thunk";
import logger from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";
import parentReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
const middleware = [thunk];

// const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = composeWithDevTools({ trace: true }); // <<< set trace true for tracking

function configureStore() {
  return createStore(
    parentReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
}

const store = configureStore();

export default store;
